(function () {
    "use strict";

    const itemDescriptions = document.querySelectorAll(
        ".news-refresh__item-container p"
    );

    if (itemDescriptions && itemDescriptions.length > 0) {
        itemDescriptions.forEach(function (element) {
            const itemDescription = element.innerHTML
                .trim()
                .replace(/^\s+|\s+$/gm, "");
            const maxLength = 240;

            if (itemDescription.length > maxLength) {
                const truncatedText =
                    itemDescription.substring(0, maxLength - 3) + "...";
                element.innerHTML = truncatedText;
            }
        });
    }
})();
